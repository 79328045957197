import {
    type ZodRawShape,
    ZodObject,
    ZodBoolean,
    ZodDefault,
    ZodOptional,
    z,
    type ZodTypeAny, ZodType
} from 'zod'
import type { InternalFormElementValue } from '@core-types/components/CoreUIForm'
import type { MaybeRef, ModelRef } from 'vue'
import type { NullToUndefined } from '@core-types/utility'

export type FormDataObject<T extends ZodRawShape> = {
    [K in keyof T]: T[K] extends ZodObject<infer U extends ZodRawShape>
        ? FormDataObject<U>
        : InternalFormElementValue<ReturnType<T[K]['_def']['defaultValue']>>
}

/**
 * Creates an object with the data structure for our custom form component.
 * This is used to create a reactive object that will be used to store the form data.
 *
 * **Meaning of the properties:**
 * - `__v` - the value of the form field
 * - `__f` - the path to the form field (nested fields are separated by a dot: e.g. `address.street`)
 * - `__r` - whether the form field is required or not
 *
 * @example
 * const schema = z.object({
 *    name: z.string(),
 *    age: z.number().optional(),
 *    address: z.object({
 *      street: z.string().default(''),
 *      city: z.string().default('New York'),
 *    }),
 *  })
 *
 *  const formData = getFormDataObjectFromZodSchema(schema)
 *
 *  // formData is now:
 *  {
 *    name: {
 *      __v: undefined,
 *      __f: 'name',
 *      __r: true,
 *    },
 *    age: {
 *      __v: undefined,
 *      __f: 'age',
 *      __r: false,
 *    },
 *    address: {
 *      street: {
 *        __v: '',
 *        __f: 'address.street',
 *        __r: true,
 *      },
 *      city: {
 *        __v: 'New York',
 *        __f: 'address.city',
 *        __r: true,
 *      },
 *    },
 *  }
 *
 *
 * @todo maybe add support for arrays?
 * @param schema The Zod schema to get the plain object with default form values from.
 * @param pathPrefix The path prefix to be used for the form field names. (only used internally)
 * @param initialData The initial data for the form fields
 * @todo refactor
 */
export function getFormDataObjectFromZodSchema<T extends ZodRawShape>(schema: MaybeRef<ZodObject<T>>, pathPrefix: string | null = null, initialData: Partial<z.infer<ZodObject<T>>> | null = null): FormDataObject<T> {
    const path = pathPrefix ? `${pathPrefix}.` : ''

    const schemaValue = toValue(schema)

    return Object.fromEntries(
        Object.entries(schemaValue.shape).map(([key, value]) => {
            // process nested objects
            if (value._def?.typeName === 'ZodObject') {   // warning - the IDE warns that this check is redundant - IT'S NOT!
                return [key, getFormDataObjectFromZodSchema(value as ZodObject<any>, `${path}${key}`, initialData?.[key] ?? null)]
            }

            // TODO: maybe refactor without the use of ternaries
            const defaultValue = initialData?.[key] !== undefined
                ? initialData?.[key]
                // root object is a default
                : value._def?.typeName === 'ZodDefault'
                    ? value._def.defaultValue?.() ?? undefined  // use the root object's default value
                    // if root object is optional, check for inner default
                    : value._def?.typeName === 'ZodOptional'
                        // inner object is default
                        ? value._def.innerType._def?.typeName === 'ZodDefault'
                            ? value._def.innerType._def.defaultValue?.() ?? undefined   // use inner object's default value
                            // inner object is bool -> we don't have a default value
                            : value._def.innerType._def?.typeName === 'ZodBoolean'
                                ? false // set undefined default value of a bool to false (so that checkboxes work correctly)
                                : undefined
                        // root object is not a default nor an optional -> check whether it's a boolean and handle the special case
                        : value._def?.typeName === 'ZodBoolean'
                            ? false // set undefined default value of a bool to false (so that checkboxes work correctly)
                            : undefined

            /*
             * Checks whether the current filed is required or not.
             * Based on the order of `.default()` and `.optional()`, the root value can be either
             * a ZodDefault object or a ZodOptional object. Therefore, we need to check for it.
             * If the root value is ZodDefault, we need to check the value of `isOptional()` for the subtype.
             */
            const isFieldRequired = value._def?.typeName === 'ZodDefault'
                ? !(value._def.innerType.isOptional())
                : !value.isOptional()


            // return binding object for terminal fields
            return [
                key,
                {
                    __v: defaultValue,
                    __f: `${path}${key}`,
                    __r: isFieldRequired,
                },
            ] satisfies [string, InternalFormElementValue<unknown>]
        })
    )
}

export function useFormData() {
    const { injected } = useCoreUiFormProvide<any, unknown>()

    function setFormDataValue<T>(model:  ModelRef<InternalFormElementValue<T> | undefined, string>, value: T) {
        const modelValue = toValue(model)
        if (!model || !modelValue || typeof modelValue === 'string' || !model.value) return

        model.value.__v = value

        injected.bus?.emit({
            type: 'change',
            __f: model.value.__f,
        })
    }

    function getFormDataValue<T>(model: ModelRef<InternalFormElementValue<T>>): T {
        return model.value.__v
    }

    return {
        setFormDataValue,
        getFormDataValue,
    }
}

// TODO: refactor
export function updateFormDataObjectFromZodSchema<T extends ZodRawShape>(formData: FormDataObject<T>, schema: MaybeRef<ZodObject<T>> | null, _newFormData: FormDataObject<T> | null = null) {
    if (!schema && !_newFormData) {
        throw new Error('updateFormDataObjectFromZodSchema() needs either a form schema or _newFormData object.')
    }
    const newFormData = _newFormData ?? getFormDataObjectFromZodSchema(schema!)

    for (const key in formData) {
        // @ts-ignore
        if (formData[key]?._def?.typeName === 'ZodObject') {
            updateFormDataObjectFromZodSchema(formData[key] as any, null, newFormData[key] as any)
            continue
        }

        // set the new required value
        const wasPreviouslyRequired = formData[key].__r
        formData[key].__r = newFormData[key].__r

        // if the field was required, and now it's not, unset its value
        if (wasPreviouslyRequired && !formData[key].__r) {
            formData[key].__v = newFormData[key].__v
        }
    }

    return formData
}

// /**
//  * A wrapper for a zod object rule that makes it optional based on the value of the provided parameter.
//  * This is useful when needed to create
//  * @param type
//  * @param isOptional
//  * @todo add support for object syntax
//  */
// export function zOptionalIf<T extends ZodTypeAny>(type: T, isOptional: MaybeRef<boolean>) {
//     return toValue(isOptional) ? type.optional() : type
// }

export function zRequiredIf<T extends ZodTypeAny | Record<string, ZodTypeAny>>(
    type: T,
    isRequired: MaybeRef<boolean>
    // @ts-ignore
    // TODO: fix type and maybe use T[K]['_def'] instead of '_input'
): T extends ZodTypeAny ? ZodType<T['_output'], T['_input']> : { [K in keyof T]: ZodType<T[K]['_output'], T[K]['_input']> } {
    const isRequiredValue = toValue(isRequired)

    if (type?._def?.typeName) {
        // Handling when type is ZodTypeAny
        // @ts-ignore
        return (isRequiredValue ? type : type.optional()) as T extends ZodTypeAny ? ZodType<T['_output'], T['_input'], T['_output']> : never
    }

    // Handling when type is a record of string keys to ZodTypeAny values
    // @ts-ignore
    const result: Partial<{ [K in keyof T]: ZodType<T[K]['_output'], T[K]['_input'], T[K]['_output']> }> = {}
    for (const key in type) {
        const value = type[key]
        // @ts-ignore
        if (!(value?._def?.typeName)) {
            continue // Or handle error
        }
        // @ts-ignore
        result[key] = isRequiredValue ? value : value.optional()
    }
    // @ts-ignore
    return result as T extends Record<string, ZodTypeAny> ? { [K in keyof T]: ZodType<T[K]['_output'], T[K]['_input'], T[K]['_output']> } : never
}

export function defineInitialFormData<T extends ZodObject<any>>(initialData: { [K in keyof z.infer<T>]?: z.infer<T>[K] | null | undefined }) {
    return Object.fromEntries(
        Object.entries(initialData).map(([key, value]) => {
            return [key, value ?? undefined]
        })
    ) as NullToUndefined<z.infer<T>>
}

export type FormOnSubmit<T extends ZodObject<any>> = (data: z.infer<T>) => void | Promise<void>
